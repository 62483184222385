'use strict';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// Vanilla JS

// Location picker

const locationNavToggle = document.querySelector('.location-selector__toggle');
const locationNav = document.querySelector('.location-popover-menu');

if(locationNavToggle) {

	locationNavToggle.addEventListener('click', function(){
		locationNav.classList.toggle('is-open');
	});

}

var locationToggles = document.querySelectorAll('.locations-list__toggle');

locationToggles.forEach(function(locationToggle){

	locationToggle.addEventListener('click', function(){
		var currentTabData = document.querySelector('.appointment-grid--dynamic .appointment-grid__item[data-location="' + this.dataset.locationTrigger + '"]');

		// remove classess
		var allLocations = document.querySelectorAll('.appointment-grid--dynamic .appointment-grid__item.is-open');

		allLocations.forEach(function(location){
			location.classList.remove('is-open');
		})

		locationNav.classList.remove('is-open');
		// add classes
		currentTabData.classList.add('is-open');
	});

});

// Mobile navigation

const mobileNavToggle = document.querySelector('.mobile-nav__btn');
const mobileNav = document.querySelector('.popover-menu');

mobileNavToggle.addEventListener('click', function(){
  mobileNav.classList.toggle('is-open');
});

const subNavToggle = document.querySelector('.sub-nav-toggle');
const subNav = document.querySelector('article aside');

if(subNavToggle) {

	subNavToggle.addEventListener('click', function(){
		subNav.classList.toggle('is-open');
	});

}

// jQuery

jQuery(function ($) {
  $('.accordion .accordion__title').click(function() {
    $('.accordion .accordion__title').removeClass('active');
    $('.accordion .accordion__content').slideUp('normal');
    if($(this).next().is(':hidden') == true) {
      $(this).addClass('active');
      $(this).next().slideDown('normal');
    }
    return false;
  });
  $('.accordion .accordion__content').hide();
  $(".brands").slick({
		// settings: "unslick",
		speed: 300,
		slidesToShow: 6,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: false,
		infinite: true,
		dots: false,
		adaptiveHeight: false,
		pauseOnHover: false,
		centerPadding: "60px",
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
                    centerMode: true,
					centerPadding: "100px",
					// centerPadding: "160px",
				}
			},
            {
				breakpoint: 660,
				settings: {
					slidesToShow: 2,
                    centerMode: true,
					centerPadding: "100px",
				}
			},
			{
				breakpoint: 560,
				settings: {
					slidesToShow: 1,
                    centerMode: true,
					centerPadding: "120px",
				}
			}
		]
	});

});